import React, {ReactNode, useEffect} from 'react'
import styled from "styled-components";
import {Button, DatePicker, Form, Input, Select, Table} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {GroupListModel} from "../../Model/BranchModel";
import {BranchInput, ListHeader, SearchRow, SearchWrap} from "../../Components";
import {mdPg, onConfirm, queryAdd} from "../../Helper/Helper";
import {EmployeesListModel} from "../../Model/EmployeesModel";
import {useDepartment, useStore} from "../../Hooks";
import {ListProps} from "../../Model/Common";
import BranchSelect from "../../Components/BranchSelect/BranchSelect";
import {useQuery} from "../../Hooks/useQuery";
import dayjs from "dayjs";

const ListWrap = styled.div`
  box-sizing: border-box;
`

type ListModel = ColumnsType<any>

interface Props extends ListProps {
    data?: any[]
}
const AlarmList: React.FC<Props> = ({ data, }) => {
    const location = useLocation()
    const {UIStore} = useStore()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const query = useQuery()

    const {departmentData} = useDepartment()

    useEffect(()=>{
        const data: any = {}
        query.forEach((q, k) => data[k] = String(q))
        data.date_begin = data.date_begin ? dayjs(data.date_begin) : null
        data.date_end = data.date_end ? dayjs((data.date_end)) : null
        form.setFieldsValue(data)
    },[])

    const onSearch = (values: any) => {
        const params = values
        params.date_begin = params.date_begin ? params.date_begin.format("YYYY-MM-DD") : null
        params.date_end = params.date_end ? params.date_end.format("YYYY-MM-DD") : null
        params.offset = 0
        for (const [key, value] of Object.entries(params)) {
            query.set(key, value as string || "")
        }
        const newQuery = queryAdd(query)
        navigate({
            pathname: location.pathname,
            search: newQuery
        })
    }

    let columns: ListModel = [
        {
            title: '발신',
            dataIndex: 'noti_sender',
            key: 'noti_sender',
            align: "center"
        },
        {
            title: '내용',
            dataIndex: 'noti_text',
            key: 'noti_text',
            align: "center",
            render: (t, r) => <>{t} {r.noti_link ? <a href={r.noti_link} target={"_blank"}>[문서로 이동]</a> : ""}</>
        },
        {
            title: '확인',
            dataIndex: 'noti_confirm',
            key: 'noti_confirm',
            align: "center",
            render: (t) => t ? "O" : ""
        },
    ];

    return (
        <ListWrap>
            <Table
                dataSource={data}
                columns={columns}
                // pagination={onChange}
            />
        </ListWrap>
    )
}

export default AlarmList