import React, {useEffect, useState} from 'react'
import {PageWrap} from "../../Components";
import {REPO} from "../../Repository/repo";
import {TablePaginationConfig} from "antd";
import {FilterValue, SorterResult} from "antd/es/table/interface";
import {BranchListModel} from "../../Model/BranchModel";
import {queryAdd} from "../../Helper/Helper";
import {DEFAULT_LIST_COUNT} from "../../Constant/Common";
import {useLocation, useNavigate} from "react-router-dom";
import {useQuery} from "../../Hooks/useQuery";
import {useStore} from "../../Hooks";
import {DocumentListModel} from "../../Model/DocumentModel";
import DocumentsList from "../../Containers/Approval/DocumentsList";
import OperateList from "../../Containers/Operate/OperateList";
import MonthlyList from "../../Containers/Monthly/MonthlyList";
import AlarmList from "../../Containers/MyPage/AlarmList";
import {NotificationModel} from "../../Model/MyPageModel";

const AlarmListPage = () => {
    const [data, setData] = useState<NotificationModel[]>([])
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(DEFAULT_LIST_COUNT)
    const [offset, setOffset] = useState(0)
    const {UIStore} = useStore()
    const navigate = useNavigate()
    const location = useLocation()
    const query = useQuery()

    useEffect(()=>{
        fetchMethod()
    },[location])

    const fetchMethod = async () => {
        try{
            const result = await REPO.MyPage.GetAlarm({
                offset: Number(query.get("offset")),
                limit: Number(query.get("limit") || limit),
                // target: query.get("target") || UIStore.branch
            })
            setData(result.data.list.map(info => ({...info, key: info.seq})))
            setTotal(result.data.total)
            setLimit(result.data.limit)
            setOffset(result.data.offset)
        } catch (e) {

        }
    }

    const pageChangeHandler = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<BranchListModel>) => {
        if(pagination.current && pagination.pageSize){
            query.set("offset", String((pagination.current-1) * pagination?.pageSize))
            query.set("limit", String(pagination.pageSize))
            const newQuery = queryAdd(query)
            navigate({
                pathname: location.pathname,
                search: newQuery
            })
        }
    };

    return (
        <PageWrap>
            <h3>알림 목록</h3>
            <AlarmList data={data} onChange={pageChangeHandler}/>
        </PageWrap>
    )
}

export default AlarmListPage