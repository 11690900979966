import {DO_API} from "./Api";
import {RequestAuthModel, ResponseTokenModel} from "../Model/AuthModel";
import {AxiosResponse} from "axios";
import {UserInfoModel} from "../Model/UserModel";
import {throws} from "assert";
import useDepartment from "../Hooks/useDepartment";
import {DepartmentItemModel} from "../Model/DepartmentModel";
import {
    BranchDetailModel,
    BranchListModel,
    GroupDetailModel,
    GroupListModel,
    RequestBranchListModel, RequestBranchModel,
    RequestGroupModel
} from "../Model/BranchModel";
import {DefaultApiResponse, EnvModel, FileModel, ListModel, ListRequestModel} from "../Model/Common";
import BranchDetail from "../Containers/Branch/BranchDetail";
import {do_branch, do_employee} from "../Model/DBModel";
import {
    EmployeeDetailModel,
    EmployeesListModel,
    RequestEmployeeModel,
    RequestEmployeesListModel
} from "../Model/EmployeesModel";
import {
    DocumentDetailModel,
    DocumentListModel,
    RequesetDocumentModel,
    RequestDocumentListModel
} from "../Model/DocumentModel";
import {
    BeforeManagementModel,
    DataInquiryModel,
    ManagementModel, ManagementStatus, MonthlyList, MonthStatus, RequestDataInquiryModel, RequestDefaultManagementModel,
    RequestManagementModel, RequestManagementStatus,
    RequestWorkModel,
} from "../Model/OperateModel";
import {BoardDetailModel, BoardItemModel, BoardListRequestModel} from "../Model/BoardModel";
import {NotificationModel} from "../Model/MyPageModel";
import {ResponseMonthlyModel} from "../Model/MonthlyModel";
import {TableCommentAddRequest, TableCommentListResponse} from "../Model/CommentModel";

export const REPO = {
    System: {
        Upload: async(form: FormData): Promise<AxiosResponse<FileModel>> => {
            return await DO_API.post('/system/upload', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        },

        Main: async () :Promise<AxiosResponse<{notice: BoardItemModel[], document: DocumentListModel[]}>> => {
            return await DO_API.get("/system/main")
        },

        Env: async () :Promise<AxiosResponse<{ env: EnvModel[] }>> => {
            return await DO_API.get("/system/env")
        },

        EnvSave: async (env: EnvModel[]) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post("/system/env", {env: env})
        },

        PushTest: async () : Promise<AxiosResponse<DefaultApiResponse>> => {
            return  await DO_API.post('/system/push-test')
        }
    },

    Auth: {
        Login: async (params: RequestAuthModel): Promise<AxiosResponse<ResponseTokenModel>> => {
            return await DO_API.post("/auth/token", params)
        },

        Revoke: async (): Promise<AxiosResponse<ResponseTokenModel>> => {
            return await DO_API.post("/auth/revoke")
        },

        Refresh: async (params: RequestAuthModel): Promise<AxiosResponse<ResponseTokenModel>> => {
            if(!params.refresh_token){
                return Promise.reject()
            }
            return await DO_API.post("/auth/token", params)
        },

        AuthCode: async (id: string): Promise<AxiosResponse<{ request_id: number }>> => {
            return await DO_API.post("/auth/authcode", {
                "auth_type": "phone",
                id: id
            })
        },

        AuthPassword: async (id: number, authcode:string, new_password: string): Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.put("/auth/password", {
                request_id: id,
                authcode: authcode,
                new_password: new_password
            })
        },
    },

    User: {
        Info: async () :Promise<AxiosResponse<UserInfoModel>> => {
            return await DO_API.get("/auth/userinfo")
        }
    },

    Department: {
        My: async () :Promise<AxiosResponse<DepartmentItemModel[]>> => {
            return await DO_API.get("/department")
        }
    },

    Branch: {
        GetGroupList: async (params: ListRequestModel) :Promise<AxiosResponse<ListModel<GroupListModel>>> => {
            return await DO_API.get("/department/group", {params: params})
        },

        GroupShowChagne: async (seq_arr: number[], state: 0 | 1) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.patch("/department/group", {
                "seq_array": seq_arr,
                "group_show": state
            })
        },

        GroupAdd: async (params: GroupListModel) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post("/department/group", {
                "group": params
            })
        },

        GroupRemove: async (seq: number) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post("/department/group/delete", {
                "seq_array": [seq],
            })
        },

        GroupDetail: async (id: number) :Promise<AxiosResponse<GroupDetailModel>> => {
            return await DO_API.get(`/department/group/${id}`)
        },

        GroupModify: async (id: number, params: RequestGroupModel) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.put(`/department/group/${id}`, params)
        },

        GetBranchList: async (params: RequestBranchListModel) :Promise<AxiosResponse<ListModel<BranchListModel>>> => {
            return await DO_API.get("/department/branch", {params: params})
        },

        BranchAdd: async (params: do_branch) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post("/department/branch", {
                "branch": params
            })
        },

        BranchDetail: async (id: number) :Promise<AxiosResponse<BranchDetailModel>> => {
            return await DO_API.get(`/department/branch/${id}`)
        },

        BranchModify: async (id: number, params: RequestBranchModel) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.put(`/department/branch/${id}`, params)
        },

        BranchRemove: async (seq: number) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post("/department/branch/delete", {
                "seq_array": [seq],
            })
        },

    },

    Employee: {
        GetEmployeeList: async (target: string, params: RequestEmployeesListModel) :Promise<AxiosResponse<ListModel<EmployeesListModel>>> => {
            return await DO_API.get(`/${target}/employee`, {params: params})
        },

        EmployeeAdd: async (target: string, params: do_employee) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post(`/${target}/employee`, {
                "employee": params
            })
        },

        EmployeeDetail: async (target: string, id: number) :Promise<AxiosResponse<EmployeeDetailModel>> => {
            return await DO_API.get(`/${target}/employee/${id}`)
        },

        EmployeeModify: async (target: string, id: number, params: RequestEmployeeModel) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.put(`/${target}/employee/${id}`, params)
        },

        EmployeeStatusChange: async (target: string, status: 0 | 1, id: number) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.patch(`/${target}/employee/${id}`, {
                employee_login: status,
            })
        },

        EmployeeRemove: async (seq: number) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post("/department/employee/delete", {
                "seq_array": [seq],
            })
        },

        EmployeeRenewPassword: async (target: string, id: number | string) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post(`/${target}/employee/${id}/renew-password`)
        }

    },

    Document: {
        GetDocumentList: async (target: string, params: RequestDocumentListModel) :Promise<AxiosResponse<ListModel<DocumentListModel>>> => {
            return await DO_API.get(`/${target}/document`, {params: params})
        },

        DocumentPerson: async (target: string) :Promise<AxiosResponse<ListModel<EmployeesListModel>>> => {
            return await DO_API.get(`/${target}/document/person/`)
        },

        DocumentDetail: async (target: string, id: number) :Promise<AxiosResponse<DocumentDetailModel>> => {
            return await DO_API.get(`/${target}/document/${id}`)
        },

        DocumentAction: async (target: string, id: number, type:string, action: string) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post(`/${target}/document/${id}/action`, {
                "type": type,
                "action": action
            })
        },

        DocumentRemove: async (target: string, id: number) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.delete(`/${target}/document/${id}`)
        },

        DocumentPost: async (target: string, params: RequesetDocumentModel):Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post(`/${target}/document/`, params)
        },

        DocumentPut: async (target: string, id: number | string, params: RequesetDocumentModel):Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.put(`/${target}/document/${id}`, params)
        },
    },

    Operate: {
        GetManagementStatus: async (target: string, params: RequestManagementStatus, ) :Promise<AxiosResponse<ManagementStatus>> => {
            return await DO_API.get(`/${target}/management/status`, {params: params})
        },


        GetManagement: async (target: string, date: string, branch_seq?: number) :Promise<AxiosResponse<{ management: ManagementModel, before_management: BeforeManagementModel, exists_days_by_month: string[]  }>> => {
            return await DO_API.get(`/${target}/management/${date}`, {params: {branch_seq: branch_seq}})
        },

        PostManagement: async (target: string, date: string, data:RequestManagementModel, branch_seq: number) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post(`/${target}/management/${date}?branch_seq=${branch_seq}`, {
                management:{
                    ...data
                }
            })
        },

        PostMonthSalePurchase: async (target: string, date: string, data:MonthlyList[], branch_seq: number) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post(`/${target}/management/month/sale-purchase/${date}?branch_seq=${branch_seq}`, {monthly_list: data})
        },

        DeleteManagement: async (target: string, date: string, branch_seq: number) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.delete(`/${target}/management/${date}?branch_seq=${branch_seq}`)
        },

        GetWork: async (target: string, date: string, branch_seq?: number) :Promise<AxiosResponse<{ work: RequestWorkModel, exists_days_by_month: string[] }>> => {
            return await DO_API.get(`/${target}/work/${date}`, {params: {branch_seq: branch_seq}})
        },

        PostWork: async (target: string, date: string, data:RequestWorkModel, branch_seq: number) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post(`/${target}/work/${date}?branch_seq=${branch_seq}`, {
                work:{
                    ...data
                }
            })
        },

        DeleteWork: async (target: string, date: string, branch_seq: number) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.delete(`/${target}/work/${date}?branch_seq=${branch_seq}`)
        },

        GetDefault: async (target: string, branch_seq?: number) :Promise<AxiosResponse<RequestDefaultManagementModel>> => {
            return await DO_API.get(`/${target}/management/default`, {params: {branch_seq: branch_seq}})
        },

        PostDefault: async (target: string, data:RequestDefaultManagementModel, branch_seq: number) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post(`/${target}/management/default?branch_seq=${branch_seq}`, data)
        },

        DeleteDefault: async (target: string, branch_seq: number) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.delete(`/${target}/management/default?branch_seq=${branch_seq}`)
        },

        GetData: async  (target: string, type: "sales-count" | "purchase-and-sales" | "work", params: RequestDataInquiryModel) :Promise<AxiosResponse<DataInquiryModel<any>>> => {
            return await DO_API.get(`/${target}/data/${type}`, {params: params})
        },

        GetMonthStatus: async (target: string, date: string, branch_seq?: number) :Promise<AxiosResponse<MonthStatus>> => {
            return await DO_API.get(`/${target}/management/month/${date}`, {params: {branch_seq: branch_seq}})
        },

        GetMonthFillDayOff: async (target: string, date: string, branch_seq?: number) :Promise<AxiosResponse<MonthStatus>> => {
            return await DO_API.post(`/${target}/management/month/fill-dayoff/${date}?branch_seq=${branch_seq}`, {branch_seq: branch_seq})
        },
    },

    Closing: {
        GetClosingList: async (target: string, params: ListRequestModel) :Promise<AxiosResponse<ListModel<any>>> => {
            return await DO_API.get(`/${target}/closing`, {
                params: params
            })
        },

        GetClosingDetail: async (target: string, date: string, branch_seq?: string) :Promise<AxiosResponse<{ closing: ResponseMonthlyModel, system_env: any }>> => {
            return await DO_API.get(`/${target}/closing/${branch_seq}/${date}`)
        },

        CreateClosing: async (target: string, date: string, branch_seq?: number) :Promise<AxiosResponse<{
            date: any;
            closing: ResponseMonthlyModel, system_env: any }>> => {
            return await DO_API.post(`/${target}/closing/${branch_seq}/${date}/create`, {
                "is_overwrite": true
            })
        },

        SaveClosing: async (target: string, date: string, params: ResponseMonthlyModel, branch_seq?: number) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post(`/${target}/closing/${branch_seq}/${date}`, {
                "closing": params
            })
        },

        DeleteClosing: async (target: string, date: string, branch_seq?: string) :Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.delete(`/${target}/closing/${branch_seq}/${date}`)
        },
    },

    Board: {
        GetList: async (board_id: string, params?: BoardListRequestModel): Promise<AxiosResponse<ListModel<BoardItemModel>>> => {
            return await DO_API.get(`/board/${board_id}`, {params: params})
        },

        GetDetail: async (board_id: string, id: string): Promise<AxiosResponse<{board: BoardDetailModel}>> => {
            return await DO_API.get(`/board/${board_id}/${id}`)
                .then(res=>res)
                .catch(err => err.response)
        },

        Delete: async (board_id: string, id: string): Promise<AxiosResponse<{board: BoardDetailModel}>> => {
            return await DO_API.delete(`/board/${board_id}/${id}`)
        },

        Update: async (board_id: string, id: string, params: BoardDetailModel): Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.put(`/board/${board_id}/${id}`, {board: params})
        },

        Add: async (board_id: string, params: BoardDetailModel): Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post(`/board/${board_id}/`, {board: params})
        },
    },

    MyPage: {
        GetAlarm: async (params: ListRequestModel): Promise<AxiosResponse<ListModel<NotificationModel>>> => {
            return await DO_API.get(`/system/notification`, {params: params})
        },
    },

    Comment: {
        Add: async (params: TableCommentAddRequest): Promise<AxiosResponse<DefaultApiResponse>> => {
            return await DO_API.post("/comment/" + params.table_row, {
                comment_text: params.comment_text,
                comment_file: params.comment_file
            })
        },

        Get: async (table_row: string): Promise<AxiosResponse<TableCommentListResponse>> => {
            return await DO_API.get("/comment/" + table_row)
        },
    }

}